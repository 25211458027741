/**
 * Appointment Status
 *
 * ```
 * Vetspire        Mongo-DB
 *
 * ADMITTED        inProgress
 * CANCELLED:      cancelled
 * CHECKED_IN:     present
 * CHECKING_OUT:   inProgress
 * COMPLETED:      discharged
 * CHECKED_OUT:    discharged
 * CONFIRMED:      confirmed
 * DAYCARE:        inProgress
 * INPROGRESS:     inProgress
 * MEDBOARD:       inProgress
 * NOSHOW:         noShow
 * PENDING:        absent
 * PLANNED:        absent
 * ```
 */
// eslint-disable-next-line import/no-cycle
import {
    AgeUnit,
    AgeUnitDefinition,
    type Animal,
    Sex,
    SexDefinition,
} from '../animals';
import {
    EMAIL_TYPE_NAME,
    INTERNAL_ID_TYPE_NAME,
    type OperationResult,
    type Projection,
} from '../common';
import { LEAD_IMPORT_SUBSCRIPTION_PREFERENCES_TARGETS } from '../communication';
import type { Lead } from '../newsletter';
import type { FeeProductType } from '../noShows';
import type { User } from '../users';
import { VETSPIRE_APPOINTMENT_STATUS } from '../vetspire';

export enum AppointmentStatus {
    absent = 'absent',
    confirmed = 'confirmed',
    cancelled = 'cancelled',
    removed = 'removed',
    present = 'present',
    ready = 'ready',
    roomed = 'roomed',
    inProgress = 'inProgress',
    completed = 'completed',
    discharged = 'discharged',
    noShow = 'noShow',
    checkIn = 'checkIn',
    create = 'create',
}

export const VETSPIRE_APPOINTMENT_STATUS_MAP: Record<
    AppointmentStatus,
    keyof typeof VETSPIRE_APPOINTMENT_STATUS
> = {
    absent: 'PLANNED',
    confirmed: 'CONFIRMED',
    create: 'PLANNED',
    ready: 'PENDING',
    discharged: 'COMPLETED',
    removed: 'CANCELLED',
    cancelled: 'CANCELLED',
    roomed: 'INPROGRESS',
    noShow: 'NOSHOW',
    checkIn: 'CHECKED_IN',
    inProgress: 'INPROGRESS',
    present: 'CHECKED_IN',
    completed: 'COMPLETED',
};

export const APPOINTMENT_STATUS = Object.freeze(AppointmentStatus);

export enum SurveyStatus {
    sent = 'sent',
    noPhoneNumber = 'noPhoneNumber',
    notSentDeceased = 'notSentDeceased',
    notSentSurveyNotActive = 'notSentSurveyNotActive',
    error = 'error',
    received = 'received',
}

export type SurveyStatusType = null | SurveyStatus;

export const SURVEY_STATUS = Object.freeze({
    ...SurveyStatus,
    notProcessed: null,
});

export type NpsSurvey = {
    status: SurveyStatus;
    error?: string | null;
    score?: number;
    responseMessage?: string;
    responseMessages?: { receivedAt: Date; content: string }[];
};

/**
 * possible origins of an appointment
 * @enum
 */
export enum AppointmentOrigin {
    unknown = 'unknown',
    website = 'website',
    clientApp = 'clientApp',
    ios = 'ios',
    clockwise = 'clockwise',
    vetspire = 'vetspire',
    bondFlow = 'bondFlow',
    telehealth = 'telehealth',
    chatbot = 'chatbot',
}

export const APPOINTMENT_ORIGIN = Object.freeze(AppointmentOrigin);

export enum RescheduleAppointmentOrigin {
    chatbot = 'chatbot',
    sms = 'sms',
    email = 'email',
    vetspire = 'vetspire',
    unauthenticatedLink = 'unauthenticated_link',
    website = 'website',
    clientApp = 'clientApp',
}

export const RESCHEDULE_APPOINTMENT_ORIGIN = Object.freeze(
    RescheduleAppointmentOrigin,
);

export enum AppointmentMode {
    walkIn = 'walkIn',
    scheduled = 'scheduled',
    emergency = 'emergency',
    telehealth = 'telehealth',
}

export const APPOINTMENT_MODE = Object.freeze(AppointmentMode);

export enum AppointmentReminderScheduleType {
    fixedHourOfDay = 'fixedHourOfDay',
    hoursBefore = 'hoursBefore',
}

export const APPOINTMENT_REMINDER_SCHEDULE_TYPE = Object.freeze(
    AppointmentReminderScheduleType,
);

export enum BookingMode {
    clockwise = 'clockwise',
    vetspire = 'vetspire',
}

export const BOOKING_MODE = Object.freeze(BookingMode);

/**
 * @enum
 */
export enum Species {
    canine = 'canine',
    feline = 'feline',
    other = 'other',
}

export const SPECIES = Object.freeze(Species);

/**
 * collection: vetspire.appointmentTypes
 *
 * Vetspire: https://developer.vetspire.com/object/AppointmentType
 */
export type AppointmentType = {
    /** numeric-as-string id in Vetspire (e.g. "1234") */
    _id: string;
    /** imported from Vetspire */
    name: string;
    /** imported from Vetspire (`isTelemedicine`) */
    isTelehealth: boolean;
    /** imported from Vetspire, currently not in use */
    canBookOnline: boolean;
    /** can be set here: https://team.bondvet.com/settings/independent/appointment-types */
    isEuthanasia: boolean;
    /** can be set here: https://team.bondvet.com/settings/independent/appointment-types */
    isDental: boolean;
    /** imported from Vetspire */
    isSurgery: boolean;
    /** imported from Vetspire */
    isExam: boolean;
    /** imported from Vetspire */
    isBlockoff: boolean;
    /** imported from Vetspire */
    description?: string | null;
    /** imported from Vetspire */
    color?: string | null;
    /** can be set here: https://team.bondvet.com/settings/independent/appointment-types */
    helloSignTemplateId?: string | null;
    /** can be set here: https://team.bondvet.com/settings/independent/appointment-types */
    neverSendReleaseForm?: boolean;
    /** can be set here: https://team.bondvet.com/settings/independent/appointment-types */
    neverSendFollowUpMail?: boolean;
    /** can be set here: https://team.bondvet.com/settings/independent/appointment-types */
    cleanAtEndOfDay: boolean;
    /** can be set here: https://team.bondvet.com/settings/independent/appointment-types */
    isWellness: boolean;
    /** automatically open charge fee form in Vetspire if true
     * can be set here: https://team.bondvet.com/settings/independent/appointment-types
     */
    chargeLateCancelRescheduleFee: boolean;
    /**
     * set by us in a cron job. relates to the number of times this appointment type
     * has been used in an appointment
     */
    sortOrder: number;
    /** set in cron job based on historical averages from Looker **/
    averageRevenue: string | null;
    averageRevenueUpdatedAt: Date | null;
};

export const APPOINTMENT_TYPES_COLLECTION_NAME = 'vetspire.appointmentTypes';

export type AppointmentTypeInput = {
    isEuthanasia: boolean;
    isDental: boolean;
    isWellness: boolean;
    chargeLateCancelRescheduleFee: boolean;
    helloSignTemplateId: string | null;
    neverSendReleaseForm: boolean;
    neverSendFollowUpMail: boolean;
    cleanAtEndOfDay: boolean;
};

const CONTACT_INFO_PROJECTION_FIELDS = [
    '_id',
    'firstName',
    'lastName',
    'phoneNumber',
    'defaultLocation',
    '_vetspire',
] as const;

export const ContactInfoProjection: Projection =
    CONTACT_INFO_PROJECTION_FIELDS.reduce(
        (projection, field) => ({
            ...projection,
            [field]: 1,
        }),
        {},
    );

export type ContactInfoProjectionFields =
    (typeof CONTACT_INFO_PROJECTION_FIELDS)[number];
export type ContactInfoData = Pick<User, ContactInfoProjectionFields>;

export const LEAD_CONTACT_INFO_FIELDS = [
    ...LEAD_IMPORT_SUBSCRIPTION_PREFERENCES_TARGETS,
    'phone',
] as const;

export type LeadSubscriptionStatusField =
    (typeof LEAD_CONTACT_INFO_FIELDS)[number];
export const LeadSubscriptionStatusProjection: Projection =
    LEAD_CONTACT_INFO_FIELDS.reduce(
        (projection, field) => ({
            ...projection,
            [field]: 1,
        }),
        {},
    );

export type LeadSubscriptionStatusData = Pick<
    Lead,
    LeadSubscriptionStatusField
>;

const BOOKING_PATIENT_PROJECTION_FIELDS = [
    '_id',
    'name',
    'species',
    '_vetspire',
] as const;

type BookingPatientProjectionField =
    (typeof BOOKING_PATIENT_PROJECTION_FIELDS)[number];

export type BookingPatientProjectionType = Record<
    BookingPatientProjectionField,
    1
>;

export const BookingPatientProjection: BookingPatientProjectionType =
    BOOKING_PATIENT_PROJECTION_FIELDS.reduce(
        (projection, field) => ({
            ...projection,
            [field]: 1,
        }),
        {} as BookingPatientProjectionType,
    );

export type BookingPatient = {
    _id: Animal['_id'];
    name: Animal['name'];
    species: Animal['species'];
    _vetspire?: { id?: string | null } | null;
};

export type ContactInfo = Omit<ContactInfoData, 'phoneNumber'> & {
    phoneNumberLast4: null | string;
    smsMarketing?: boolean;
    smsTransactional?: boolean;
    smsConversational?: boolean;
    vetspireId?: string | null | undefined;
};

export interface PatientAndContactInfo {
    contactInfo: ContactInfo;
    patients: ReadonlyArray<BookingPatient>;
}

export interface PatientAndContactInfoArguments {
    email: string;
}

export interface NearbyLocation {
    locationId: string;
    distance: number;
}

const BOOKING_APPOINTMENT_TYPE_PROJECTION_FIELDS = [
    '_id',
    'name',
    'isEuthanasia',
    'isTelehealth',
    'canBookOnline',
    'chargeLateCancelRescheduleFee',
    'sortOrder',
] as const;

export const BookingAppointmentTypeProjection: Projection =
    BOOKING_APPOINTMENT_TYPE_PROJECTION_FIELDS.reduce(
        (projection, field) => ({
            ...projection,
            [field]: 1,
        }),
        {},
    );

export type BookingAppointmentTypeProjectionFields =
    (typeof BOOKING_APPOINTMENT_TYPE_PROJECTION_FIELDS)[number];
export type BookingAppointmentType = Pick<
    AppointmentType,
    BookingAppointmentTypeProjectionFields
>;

export interface BaseTimeSlotsResult<DateType> {
    timezone: string;
    slots: ReadonlyArray<DateType>;
}

export type ProvidersResult = {
    id: string;
    name: string;
    isRelief: boolean;
};

export type TimeSlotsResult = BaseTimeSlotsResult<Date> & {
    locationId?: string;
};

export type SlotWithProviderId = {
    dateTime: Date;
    providerId: string;
};

export type SlotWithProvider = SlotWithProviderId & {
    provider: ProvidersResult;
};

export type TimeSlotsByProviderResult = {
    locationId: string;
    timezone: string;
    slotsWithProviders: ReadonlyArray<SlotWithProviderId | SlotWithProvider>;
};

export type GraphQLTimeSlotsResult = BaseTimeSlotsResult<string>;

export interface TimeSlotsArguments {
    locationId: string;
    from: string;
    to: string;
    firstAvailable: boolean;
}

export interface PetDetailsInput {
    breed?: string | null;
    birthDate?: string | null;
    age?: number | null;
    ageUnit?: AgeUnit | null;
    sex?: Sex | null;
    neutered?: boolean | null;
}

export interface AppointmentInput {
    clientId?: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;

    animalId?: string;
    pet: string;
    species: string;

    reminderMinutes?: number;
    sendReminder: boolean;
    sendSurvey: boolean;
    symptoms: string;
    time: Date;
    locationId: string;
    updateProfile?: boolean;
    promoCode?: string;
    notes?: string;
    creditCardSetupIntentId?: string;
    selectedProviderId?: string;

    petDetails?: PetDetailsInput;
}

export interface UpdateSubscriptionPreferences {
    subscribeSmsTransactional?: boolean;
    subscribeSmsMarketing?: boolean;
    subscribeSmsConversational?: boolean;
}

export interface CreateAppointmentArguments
    extends UpdateSubscriptionPreferences {
    input: AppointmentInput;
    origin: AppointmentOrigin;
}

export const PetDetailsInputDefinition = `
input PetDetailsInput {
    breed: String
    birthDate: DateString
    age: Int
    ageUnit: AgeUnit
    sex: Sex
    neutered: Boolean
}
`;

export const AppointmentInputDefinition = `
input AppointmentInput {
    email: ${EMAIL_TYPE_NAME}!

    """ can be omitted when clientId is provided """
    firstName: String

    """ can be omitted when clientId is provided """
    lastName: String

    """ can be omitted when clientId is provided """
    phone: String

    clientId: ${INTERNAL_ID_TYPE_NAME}

    """ can be omitted when animalId is provided """
    pet: String

    """ can be omitted when animalId is provided """
    species: String

    animalId: ${INTERNAL_ID_TYPE_NAME}

    reminderMinutes: Int = 30
    sendReminder: Boolean!
    sendSurvey: Boolean!
    """
    due to legacy reasons, this is the ID of the appointment reason (appt. type)
    """
    symptoms: String!
    time: DateTime!
    locationId: ${INTERNAL_ID_TYPE_NAME}
    updateProfile: Boolean
    promoCode: String
    notes: String
    creditCardSetupIntentId: String
    selectedProviderId: String
    petDetails: PetDetailsInput
}

`;

export const AppointmentOriginDefinition = `
enum AppointmentOrigin {
    ${Object.values(APPOINTMENT_ORIGIN).join(', ')}
}
`;

export const RescheduleAppointmentOriginDefinition = `
enum RescheduleAppointmentOrigin {
    ${Object.values(RESCHEDULE_APPOINTMENT_ORIGIN).join(', ')}
}
`;

export const AppointmentClientDefinition = `
type AppointmentClient {
    _id: ID!
    email: String
    firstName: String
    lastName: String
    phoneNumber: String
    address: String
    addressExtra: String
    city: String
    zipCode: String
    state: String
    country: String
}
`;

export const AppointmentPatientDefinition = `
type AppointmentPatient {
    _id: ID!
    name: String
    species: String!
    breed: String,
    color: String
    sex: Sex
    neutered: Boolean
    dateOfBirth: Date
    notes: String
}
`;

export const AppointmentDefinition = `
type Appointment {
    _id: ID!
    appointmentId: String
    firstName: String
    lastName: String
    clientId: ID!
    email: String
    date: DateTime!
    location: Location!
    pet: String
    species: String
    reasonId: String
    notes: String
    phoneNumber: String
    client: AppointmentClient
    patient: AppointmentPatient
    timezone: String
    hasProvider: Boolean
    isTelehealth: Boolean
    covidCheck: Boolean
    appointmentType: AppointmentType
    minCancellingMs: Int!
    minReschedulingMs: Int!
    minVeryLateCancellingMs: Int!
    minVeryLateReschedulingMs: Int!
    freeCancellingAllowed: Boolean!
    freeReschedulingAllowed: Boolean!
    freeVeryLateReschedulingAllowed: Boolean!
    freeVeryLateCancellingAllowed: Boolean!
    noShowFee: Float!
}

type BookingSettings {
    hasFirstVisitPromo: Boolean!
    firstVisitExamPromo: String
    firstVisitExamPromoHtml: String
    requirePetSignalmentForNewPets: Boolean!
    allowIndividualProviderBookings: Boolean!
}

extend type Location {
    bookingSettings: BookingSettings!
}
`;

export const AppointmentCreatedDefinition = `
type AppointmentCreated {
    success: Boolean!
    error: String
    appointment: Appointment
    last4: String
}
`;

export const APPOINTMENT_DEFINITIONS = [
    AppointmentDefinition,
    AppointmentPatientDefinition,
    AppointmentClientDefinition,
    SexDefinition,
];

export const CreateAppointmentMutationDefinition = `
extend type Mutation {
    createAppointment(
        input: AppointmentInput!
        origin: AppointmentOrigin
        subscribeSmsTransactional: Boolean
        subscribeSmsMarketing: Boolean
        subscribeSmsConversational: Boolean
    ): AppointmentCreated!
}
`;

export const CREATE_APPOINTMENT_DEFINITIONS = [
    ...APPOINTMENT_DEFINITIONS,
    AppointmentCreatedDefinition,
    AppointmentOriginDefinition,
    RescheduleAppointmentOriginDefinition,
    AppointmentInputDefinition,
    AgeUnitDefinition,
    PetDetailsInputDefinition,
    CreateAppointmentMutationDefinition,
];

export const BOOKING_SESSIONS_COLLECTION_NAME = 'booking.sessions';

export type BookingSessionInput = {
    _id?: string;
    email: string;
    userId?: string | null;
    appointmentId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    date?: string | null;
    time?: string | null;
    cityId: string;
    locationId: string;
    providerId?: string;
    animalId?: string | null;
    petName?: string | null;
    petSpecies?: Species | null;
    petAge?: number | null;
    reason?: string | null;
    notes?: string | null;
    smsTransactional?: boolean;
    smsMarketing?: boolean;
    smsConversational?: boolean;
};

export type BookingSessionType = Omit<BookingSessionInput, '_id'> & {
    _id: string;
    lastAction: Date;
    sentEmail: boolean;
    processed: boolean;
};

export const AvailableProviderDefinition = `
type AvailableProvider {
  id: String
  name: String
  isRelief: Boolean
  clinicTeamMemberId: String
}
`;

export const BookingSessionDefinition = `
type BookingSession {
    _id: ID!
    email: String!
    lastAction: DateTime!
    userId: String
    appointmentId: String
    sentEmail: Boolean!
    processed: Boolean!
    firstName: String
    lastName: String
    phoneNumber: String
    date: String
    time: String
    cityId: String
    locationId: String
    providerId: String
    animalId: String
    petName: String
    petSpecies: Species
    petAge: Int
    reason: String
    notes: String
    provider: AvailableProvider
    smsTransactional: Boolean
    smsMarketing: Boolean
    postcardMarketing: Boolean
}
`;

export const BookingSessionInputDefinition = `
input BookingSessionInput {
    email: ${EMAIL_TYPE_NAME}!
    _id: ID
    userId: String
    appointmentId: String
    firstName: String
    lastName: String
    phoneNumber: String
    date: String
    time: String
    cityId: String
    locationId: String
    providerId: String
    animalId: String
    petName: String
    petSpecies: Species
    petAge: Int
    reason: String
    notes: String
    smsTransactional: Boolean
    smsMarketing: Boolean
    postcardMarketing: Boolean
}
`;

export const BookingSessionResultDefinition = `
type BookingSessionResult {
    id: ID!
    success: Boolean!
}
`;

export const BOOKING_SESSION_DEFINITIONS = [
    BookingSessionDefinition,
    BookingSessionResultDefinition,
    BookingSessionInputDefinition,
    AvailableProviderDefinition,
];

export interface BookingSessionProvider {
    id: string;
    name: string | null;
}

/** @deprecated use CancellationReason from ClientApp */
export interface CancelationReason {
    value: string;
    label: string;
    requiresExplanation?: boolean;
}

/** @deprecated use CancellationReason and CancellationSettings from ClientApp */
export const CANCELATION_REASONS = [
    {
        value: 'condition_improved',
        label: 'My pet’s condition improved.',
    },
    {
        value: 'regular_vet',
        label: 'My regular vet was able to see me.',
    },
    {
        value: 'different_vet_clinic',
        label: 'I’m taking my pet to another general practice vet clinic. ',
    },
    {
        value: 'er',
        label: 'I’m taking my pet to the ER.',
    },
    {
        value: 'cost',
        label: 'I’m concerned about the cost.',
    },
    {
        value: 'not_able',
        label: 'I’m no longer able to make my appointment.',
    },
    {
        value: 'different_bond_clinic',
        label: 'I want to visit a different Bond Vet location.',
    },
    {
        value: 'different_bond_vet',
        label: 'I want to select a different Bond Vet veterinarian.',
    },
    {
        value: 'other',
        label: 'Other',
        requiresExplanation: true,
    },
] as const;

export const BOOKING_SETTINGS_COLLECTION_NAME = 'booking.settings';

export const BOOKING_SETTINGS_REQUIRES_CREDIT_CARD_ID = 'requiresCreditCard';

export interface RequiresCreditCardSetting {
    _id: typeof BOOKING_SETTINGS_REQUIRES_CREDIT_CARD_ID;
    enabled: boolean;
    locationIds: readonly string[];
}

export const chargeRequiresCreditCardSettingsDefault: RequiresCreditCardSetting =
    {
        _id: BOOKING_SETTINGS_REQUIRES_CREDIT_CARD_ID,
        enabled: false,
        locationIds: [],
    };

export const BOOKING_SETTINGS_CHARGE_LATE_CANCEL_FEE_ID = 'chargeLateCancelFee';

export interface WaiveReason {
    id: string;
    label: string;
}

export interface ChargeLateCancelFeeSetting {
    _id: typeof BOOKING_SETTINGS_CHARGE_LATE_CANCEL_FEE_ID;
    enabled: boolean;
    hours: number;
    waiveReasons: readonly WaiveReason[];
}

const rescheduleWaiveReasons: readonly WaiveReason[] = [
    { id: 'client_is_sick', label: 'Client is Sick' },
    { id: 'patient_is_better', label: 'Patient is Better' },
    { id: 'personal_emergency', label: 'Personal Emergency' },
    { id: 'took_patient_to_er', label: 'Took Patient to ER' },
    { id: 'work_conflict', label: 'Work Conflict' },
    { id: 'other', label: 'Other' },
];

const cancelWaiveReasons: readonly WaiveReason[] = [
    ...rescheduleWaiveReasons,
    {
        id: 'rescheduling_at_another_location',
        label: 'Rescheduling at another location',
    },
];

export const chargeLateCancelFeeSettingsDefault: ChargeLateCancelFeeSetting = {
    _id: BOOKING_SETTINGS_CHARGE_LATE_CANCEL_FEE_ID,
    enabled: false,
    hours: 24,
    waiveReasons: cancelWaiveReasons,
};

export const BOOKING_SETTINGS_CHARGE_LATE_RESCHEDULE_FEE_ID =
    'chargeLateRescheduleFee';

export interface ChargeLateRescheduleFeeSetting {
    _id: typeof BOOKING_SETTINGS_CHARGE_LATE_RESCHEDULE_FEE_ID;
    enabled: boolean;
    hours: number;
    waiveReasons: readonly WaiveReason[];
}

export const chargeLateRescheduleFeeSettingsDefault: ChargeLateRescheduleFeeSetting =
    {
        _id: BOOKING_SETTINGS_CHARGE_LATE_RESCHEDULE_FEE_ID,
        enabled: false,
        hours: 24,
        waiveReasons: rescheduleWaiveReasons,
    };

export const BOOKING_SETTINGS_CHARGE_VERY_LATE_RESCHEDULE_FEE_ID =
    'chargeVeryLateRescheduleFee';

export interface ChargeVeryLateRescheduleFeeSetting {
    _id: typeof BOOKING_SETTINGS_CHARGE_VERY_LATE_RESCHEDULE_FEE_ID;
    enabled: boolean;
    hours: number;
    waiveReasons: readonly WaiveReason[];
}

export const chargeVeryLateRescheduleFeeSettingsDefault: ChargeVeryLateRescheduleFeeSetting =
    {
        _id: BOOKING_SETTINGS_CHARGE_VERY_LATE_RESCHEDULE_FEE_ID,
        enabled: false,
        hours: 1,
        waiveReasons: rescheduleWaiveReasons,
    };

export const BOOKING_SETTINGS_CHARGE_VERY_LATE_CANCEL_FEE_ID =
    'chargeVeryLateCancelFee';

export interface ChargeVeryLateCancelFeeSetting {
    _id: typeof BOOKING_SETTINGS_CHARGE_VERY_LATE_CANCEL_FEE_ID;
    enabled: boolean;
    hours: number;
    waiveReasons: readonly WaiveReason[];
}

export const chargeVeryLateCancelFeeSettingsDefault: ChargeVeryLateCancelFeeSetting =
    {
        _id: BOOKING_SETTINGS_CHARGE_VERY_LATE_CANCEL_FEE_ID,
        enabled: false,
        hours: 1,
        waiveReasons: cancelWaiveReasons,
    };

export const BOOKING_SETTINGS_APPLY_BOOKING_FEE_GRACE_PERIOD_ID =
    'applyBookingFeeGracePeriod';

export interface ApplyBookingFeeGracePeriodSetting {
    _id: typeof BOOKING_SETTINGS_APPLY_BOOKING_FEE_GRACE_PERIOD_ID;
    enabled: boolean;
    hours: number;
}

export const applyBookingFeeGracePeriodSettingsDefault: ApplyBookingFeeGracePeriodSetting =
    {
        _id: BOOKING_SETTINGS_APPLY_BOOKING_FEE_GRACE_PERIOD_ID,
        enabled: false,
        hours: 1,
    };

export const BOOKING_SETTINGS_WAIVE_FIRST_LATE_CANCEL_RESCHEDULE_FEE_ID =
    'waiveFirstLateCancelRescheduleFee';

export interface WaiveFirstLateCancelRescheduleFeeSetting {
    _id: typeof BOOKING_SETTINGS_WAIVE_FIRST_LATE_CANCEL_RESCHEDULE_FEE_ID;
    enabled: boolean;
}

export const waiveFirstLateCancelRescheduleFeeSettingDefault: WaiveFirstLateCancelRescheduleFeeSetting =
    {
        _id: BOOKING_SETTINGS_WAIVE_FIRST_LATE_CANCEL_RESCHEDULE_FEE_ID,
        enabled: false,
    };

export const BOOKING_SETTINGS_CHATBOT_ID = 'chatBot';

export interface ChatBotSetting {
    _id: typeof BOOKING_SETTINGS_CHATBOT_ID;
    rescheduleVisitCampaign: string;
}

export const chatBotSettingDefault: ChatBotSetting = {
    _id: BOOKING_SETTINGS_CHATBOT_ID,
    rescheduleVisitCampaign: 'Rescheduling_Nurse_Visits',
};

export const BOOKING_SETTINGS_RISKY_CLIENT_REQUIRES_CREDIT_CARD_ID =
    'creditCardRequiredForRiskyClients';

export interface RiskyClientRequiresCreditCardSetting {
    _id: typeof BOOKING_SETTINGS_RISKY_CLIENT_REQUIRES_CREDIT_CARD_ID;
    enabled: boolean;
    threshold: number;
    startDate?: Date | null;
}

export const riskyClientRequiresCreditCardDefault: RiskyClientRequiresCreditCardSetting =
    {
        _id: BOOKING_SETTINGS_RISKY_CLIENT_REQUIRES_CREDIT_CARD_ID,
        enabled: false,
        threshold: 2,
        startDate: null,
    };

export type BookingSetting =
    | RequiresCreditCardSetting
    | ChargeLateCancelFeeSetting
    | ChargeLateRescheduleFeeSetting
    | ChargeVeryLateRescheduleFeeSetting
    | ChargeVeryLateCancelFeeSetting
    | ApplyBookingFeeGracePeriodSetting
    | WaiveFirstLateCancelRescheduleFeeSetting
    | RiskyClientRequiresCreditCardSetting
    | ChatBotSetting;

export type BookingSettings = {
    requiresCreditCard: RequiresCreditCardSetting;
    creditCardRequiredForRiskyClients: RiskyClientRequiresCreditCardSetting;
    chargeLateCancelFee: ChargeLateCancelFeeSetting;
    chargeLateRescheduleFee: ChargeLateRescheduleFeeSetting;
    chargeVeryLateRescheduleFee: ChargeVeryLateRescheduleFeeSetting;
    chargeVeryLateCancelFee: ChargeVeryLateCancelFeeSetting;
    applyBookingFeeGracePeriod: ApplyBookingFeeGracePeriodSetting;
    waiveFirstLateCancelRescheduleFee: WaiveFirstLateCancelRescheduleFeeSetting;
};

export const LATE_CANCEL_RESCHEDULE_WAIVE_COLLECTION_NAME =
    'lateCancelReschedule.waives';

/** all waived late cancels and late reschedules with reason and note */
export interface LateCancelRescheduleWaive {
    /** alphanumeric id (e.g. "ppJ3FgGDPiBWWG4z5") */
    _id: string;
    /** timestamp when this record was created */
    createdAt: Date;
    vetspireLocationId: string;
    vetspireClientId: string;
    vetspireAppointmentId: string;
    vetspireProviderId: string | null;
    feeProductType: FeeProductType;
    waiveReasonId: string;
    waiveReasonOtherNote?: string;
}

export interface AddLateCancelRescheduleWaiveVariables {
    input: Omit<
        LateCancelRescheduleWaive,
        '_id' | 'createdAt' | 'vetspireProviderId'
    >;
}

export interface AddLateCancelRescheduleWaiveResult {
    addLateCancelRescheduleWaive: OperationResult;
}

export interface GoogleAnalyticsTrackingStatus {
    /** if false, Google Analytics has been disabled by us */
    enabled: boolean;
    /** if true, Google Analytics has been blocked by the client */
    blocked: boolean;
}

export interface StoreGoogleAnalyticsTrackingStatusVariables {
    appointmentId: string;
    trackingStatus: GoogleAnalyticsTrackingStatus;
}

export interface StoreGoogleAnalyticsTrackingStatusResult {
    storeGoogleAnalyticsTrackingStatus: OperationResult;
}

// new rescheduleAppointment mutation in functions which is currently only a proxy towards backend
export interface RescheduleAppointmentArguments {
    appointmentId: string;
    confirmationHash: string | null | undefined;
    newTime: string;
    newVetspireLocationId: string | null | undefined;
    newVetspireProviderId: string | null | undefined;
    newPromoCode?: string | null | undefined;
    origin: RescheduleAppointmentOrigin;
}

export interface RescheduleAppointmentResult {
    rescheduleAppointment: OperationResult;
}

// new cancelAppointment mutation in functions which is currently only a proxy towards backend
export interface CancelAppointmentArguments {
    appointmentId: string;
    confirmationHash: string | null | undefined;
    reason: string;
    description: string | null | undefined;
    origin?: AppointmentOrigin | null;
    provider?: string;
}

export interface CancelAppointmentResult {
    cancelAppointment: OperationResult;
}

export type AppointmentData = {
    id: string;
    confirmationHash: string;
};

export interface BookingUrls {
    bookingUrl: string;
    telehealthUrl: string;
    chatBotUrl: string;
    rescheduleVisitChatBotUrl: string;
}
